<template>
  <v-dialog
    v-model="dialog"
    :width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-actions>
        <h3 class="font-weight-bold">
          {{ title }}
        </h3>
        <v-spacer></v-spacer>
        <!-- <v-btn class="mr-3 hide-buton" color="error" @click.native="cancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="success" class="hide-buton" @click.native="agree">
          {{ confirmText ? confirmText : $t('submit') }}
        </v-btn> -->
      </v-card-actions>
      <v-divider />
      <v-card-text
        v-show="!!message"
        class="pa-4"
      >
        {{ message }}
      </v-card-text>
      <v-divider class="" />
      <v-card-actions class="text-end">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-3"
          color="#ffeeee"
          @click.native="cancel"
        >
          <span class="error--text">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn
          v-if="confirmText === 'Delete'"
          color="error"
          class=""
          @click.native="agree"
        >
          {{ confirmText }}
        </v-btn>
        <v-btn
          v-else
          color="success"
          class=""
          @click.native="agree"
        >
          {{ confirmText ? confirmText : $t('submit') }}
        </v-btn>
      </v-card-actions>

      <!-- <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">
          {{ confirmText ? confirmText : $t('submit') }}
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    confirmText: '',
    options: {
      color: 'primary',
      width: 600,
      zIndex: 200,
    },
  }),
  methods: {
    open(title, message, options, confirmText = '') {
      this.dialog = true
      this.title = title
      this.message = message
      this.confirmText = confirmText
      this.options = Object.assign(this.options, options)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)

      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
