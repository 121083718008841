// const fileManagerOption = {
//   debug: true,
//   endPoint: 'https://fm.prod.marketa.id/media-manager',
//   isAdmin: false,
//   entryPoints: [
//     {
//       label: crm_dev,
//       directory: `crm_dev/${subId}`,
//       origin: 'public_uploads',
//       readOnly: false,
//       icon: 'famfm-folder',
//       webPrefix: '/uploads',
//     },
//   ],
//   locale: 'en',
//   multiple: true,
//   // fileValidation: {
//   //   mimeGroup: "image",
//   //   // allowDir: false,
//   //   // imageOptions: {
//   //   //   // allowSvg: true,
//   //   //   width: 1920,
//   //   //   height: 1080,
//   //   // },
//   // },
//   fileUpload: {
//     maxFileSize: 500 * 1024 * 1024, // 500Mo
//     fileType: [
//       'text/*',
//       'image/jpeg', // image/vnd.adobe.photoshop  image/x-xcf
//       'image/jpg',
//       'image/png',
//       'video/*',
//       'audio/*',
//       'application/rtf',
//       'application/pdf',
//       'application/xml',
//       'application/zip',
//       'font/ttf',
//       'font/woff',
//       'font/woff2',
//       'application/vnd.oasis.opendocument.spreadsheet', // libreoffice ods
//       'application/vnd.oasis.opendocument.text', // libreoffice odt
//       'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
//       'application/msword', // doc
//       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
//       'application/vnd.ms-excel', // xls
//       'application/json',
//       'application/illustrator', // .ai
//     ],
//   },

//   form: {
//     filter: 'small',
//     type: 'image',
//   },
// }
function fileManagerOption(subId) {
  return {
    debug: true,
    endPoint: 'https://fm.prod.marketa.id/media-manager',
    isAdmin: false,
    entryPoints: [
      {
        label: `crm_dev/${subId}`,
        directory: `crm_dev/${subId}`,
        origin: 'public_uploads',
        readOnly: false,
        icon: 'famfm-folder',
        webPrefix: '/uploads',
      },
    ],
    locale: 'en',
    multiple: true,
    // fileValidation: {
    //   mimeGroup: "image",
    //   // allowDir: false,
    //   // imageOptions: {
    //   //   // allowSvg: true,
    //   //   width: 1920,
    //   //   height: 1080,
    //   // },
    // },
    fileUpload: {
      maxFileSize: 500 * 1024 * 1024, // 500Mo
      fileType: [
        'text/*',
        'image/jpeg', // image/vnd.adobe.photoshop  image/x-xcf
        'image/jpg',
        'image/png',
        'video/*',
        'audio/*',
        'application/rtf',
        'application/pdf',
        'application/xml',
        'application/zip',
        'font/ttf',
        'font/woff',
        'font/woff2',
        'application/vnd.oasis.opendocument.spreadsheet', // libreoffice ods
        'application/vnd.oasis.opendocument.text', // libreoffice odt
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.ms-excel', // xls
        'application/json',
        'application/illustrator',
        'application/octet-stream' // .ai
      ],
    },

    form: {
      filter: 'small',
      type: 'image',
    },
  }
}

export default fileManagerOption
